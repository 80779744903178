/* Global styles - edit only if you have to */
* {
  font-family: Proxima Nova, sans-serif;
}

p, div, span {
  font-family: Proxima Nova, sans-serif !important;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./static/fonts/ProximaNova/ProximaNova-Regular.ttf");
  font-style: normal;
}
